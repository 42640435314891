import * as React from "react";
import { locKeys } from "components/flexComponents/WizardFlightPWA/components/l10n";
import { observer } from "mobx-react";
import { UitkMenu, UitkMenuTrigger, UitkMenuContainer, PositionType, UitkMenuList } from "@egds/react-core/menu";
import { UitkIcon, UitkIconSize } from "@egds/react-core/icons";
import { useLocalization } from "@shared-ui/localization-context";
import { FlightType } from "src/stores/wizard/state/typings";
import { UitkLink } from "@egds/react-core/link";
export interface FlightTypeMenuProps {
  showOneway?: boolean;
  onFlightTypeUpdate: (flightType: FlightType) => void;
  activeFlightType: FlightType;
}

export interface FlightTypeMenuItem {
  attributes: {
    role: string;
    tabindex: number;
  };
  className: string;
  href: string;
  label: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  typeAnchor: boolean;
}

interface FlightTypeLocKeysMapTyping {
  [FlightType.ROUND_TRIP]: string;
  [FlightType.ONE_WAY]: string;
  [FlightType.MULTI_CITY]: string;
}

const FlightTypeLocKeysMap: FlightTypeLocKeysMapTyping = {
  [FlightType.ROUND_TRIP]: locKeys.flightRoundTripText,
  [FlightType.ONE_WAY]: locKeys.flightOneWayText,
  [FlightType.MULTI_CITY]: locKeys.flightMultiCityText,
};

export const FlightTypeMenu = observer(({ showOneway, activeFlightType, onFlightTypeUpdate }: FlightTypeMenuProps) => {
  const { formatText } = useLocalization();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const buttonRef: React.RefObject<HTMLButtonElement> = React.createRef();

  const onMenuItemClick = (flightType: FlightType) => (event: React.MouseEvent) => {
    event.preventDefault();
    setIsMenuOpen(false);
    onFlightTypeUpdate(flightType);
    buttonRef.current?.focus();
  };

  function createMenuItem(locKey: string, pwaSubLob: FlightType): FlightTypeMenuItem {
    return {
      attributes: {
        role: "menuItem",
        tabindex: 0,
      },
      className: "uitk-list-item",
      href: "",
      label: formatText(locKey),
      onClick: onMenuItemClick(pwaSubLob),
      typeAnchor: true,
    };
  }

  const menuItems: FlightTypeMenuItem[] = [];
  menuItems.push(createMenuItem(locKeys.flightRoundTripText, FlightType.ROUND_TRIP));
  if (showOneway) {
    menuItems.push(createMenuItem(locKeys.flightOneWayText, FlightType.ONE_WAY));
  }

  /* istanbul ignore next */
  const onMenuClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsMenuOpen((prevState) => !prevState);
  };
  const activeFlightTypeLocKey = FlightTypeLocKeysMap[activeFlightType as keyof FlightTypeLocKeysMapTyping];

  return (
    <UitkMenu id="flight-type-menu" isOpen={isMenuOpen} onTriggerClick={onMenuClick}>
      <UitkMenuTrigger>
        <UitkLink>
          <button ref={buttonRef}>
            {formatText(activeFlightTypeLocKey)}
            <UitkIcon name="expand_more" size={UitkIconSize.SMALL} />
          </button>
        </UitkLink>
      </UitkMenuTrigger>
      <UitkMenuContainer position={PositionType.LEFT}>
        <UitkMenuList items={menuItems} />
      </UitkMenuContainer>
    </UitkMenu>
  );
});
