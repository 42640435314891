import { TRAVELERS_HIDDEN_INPUT_TYPE } from "components/shared/TravelersField";
import { FlightWizardState } from "stores/wizard/state";
import { useConfigOverrides } from "stores/wizard/config/overrideConfigByView/useConfigOverrides";

export const useHotwireFlightOverrides = (flightWizardState: FlightWizardState) =>
  useConfigOverrides(flightWizardState, () => {
    const { date, travelers, form } = flightWizardState.config;

    date.defaultOffsetDates = {
      start: 1,
      end: 2,
    };
    date.queryParam = {
      start: "startDate",
      end: "endDate",
    };

    form.action = "https://www.hotwire.com/air/dispatch.jsp";
    form.submitButton.locToken = "hotwire.wizard.flight.submit";

    travelers.childrenCaptionToken = "wizard.roomPicker.children.caption";
    travelers.labelToken = "hotwire.wizard.flight.travelers.title";
    travelers.dialogTitleToken = "hotwire.wizard.flight.travelers.title";
    travelers.travelersToken = "hotwire.wizard.flight.travelers";
    travelers.hiddenInputType = TRAVELERS_HIDDEN_INPUT_TYPE.HOTWIRE_FLIGHT_SEARCH;
    travelers.withAges = false;
    travelers.withInfants = false;
  });
