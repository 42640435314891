export const locKeys = {
  flightOriginFieldLabel: "wizard.origin.label",
  flightWarningInvalidOrigin: "wizard.origin.warning.invalidOrigin.text",
  flightDestinationFieldLabel: "wizard.flight.destination.label",
  flightFlightTypeLabel: "wizard.flight.flightType.label",
  flightRoundTripText: "wizard.flight.roundTrip.text",
  flightOneWayText: "wizard.flight.oneway.withHyphen.text",
  flightMultiCityText: "wizard.flight.multiCity.withHyphen.sentenceCase.text",
  flightPreferredClassEconomy: "wizard.flight.preferredClass.economy.text",
  flightPreferredClassBusiness: "wizard.flight.preferredClass.businessClass.text",
  flightPreferredClassFirstClass: "wizard.flight.preferredClass.firstClass.text",
  flightPreferredClassPremiumEconomy: "wizard.flight.preferredClass.premiumEconomy.text",
  flightPreferredClassLabel: "wizard.flight.preferredClass.label",
  flightWarningInvalidOriginFlyingFrom: "wizard.flight.warning.invalidOrigin.text",
  flightWarningInvalidDestinationFlyingTo: "wizard.flight.warning.invalidDestination.text",
  flightAirlineAgeRules: "wizard.airline.ageRules.label",
  flightChildrenInSeatRules: "wizard.airline.children.seatRules",
  flightChildrenInSeatOption: "wizard.airline.children.seatOption.inSeat",
  flightChildrenInLapOption: "wizard.airline.children.seatOption.onLap",
  addHotelLabel: "wizard.package.addHotel.label",
  addCarLabel: "wizard.package.addCar.label",
  addFlightLabel: "wizard.flight.tab.multicity.addAnotherFlight",
  removeFlightLeg: "wizard.flight.multiCity.removeFlightLeg",
  searchButton: "wizard.submit.label",
  multiCityFlightLegLabel: "wizard.flight.tab.multicity.flightLeg",
  multiCityFlightRemoveLabel: "wizard.flight.multiCity.remove.text",
  multiCityFlightRemoveA11y: "wizard.flight.multiCity.remove.a11yLabel",
  multiCityFlightRemovedLiveAnnouce: "wizard.flight.multiCity.flightRemoved.liveAnnounce",
  flightTypeDialogTitle: "wizard.flight.flightType.label",
  flightTypeDialogDoneButton: "wizard.roomPicker.doneButton.label",
  flightTypeDialogClearButton: "typeahead.templates.clear",
  flightTypeDialogDismissLabel: "typeahead.dismiss",
};
